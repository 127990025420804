<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="6" class="d-flex align-center">
        <span class="text-h5">Список груп контрагентов</span>
        <v-dialog v-model="formDialog" width="500" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary"
                   v-bind="attrs" v-on="on" small
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
          </template>
          <group-form v-if="formDialog"
                        @group:form:saved="closeForm"
                        @group:form:cancel="closeForm"
          ></group-form>
        </v-dialog>
      </v-col>
    </v-row>

    <v-data-table :items="groups"
      :headers="nonSortableHeaders"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных"
      loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.name}}</td>
          <td class="text-right">
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon left small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Group')

  import GroupForm from '@/modules/group/form'

  import {hasFilter, hasDialog} from '@/mixins'

  export default {
    name: 'group-list',

    mixins: [hasFilter, hasDialog],

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState(['data', 'fetching', 'deleting',]),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Название', value: 'full_name'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      groups() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      showForm(item) {
        this.formDialog = true
        this.setModel(item)
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список груп контрагентов')
    },

    components: {
      GroupForm,
    }
  }
</script>
