<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить карточку сотрудника' : 'Создать карточку сотрудника'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="last_name" rules="required|max:100">
                <v-text-field v-model="model.last_name"
                              required :error-messages="errors"
                              label="Фамилия"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="first_name" rules="required|max:100">
                <v-text-field v-model="model.first_name"
                              required :error-messages="errors"
                              label="Имя"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="middle_name" rules="max:100">
                <v-text-field v-model="model.middle_name"
                              required :error-messages="errors"
                              label="Отчество"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="salary_rate" rules="required|integer">
                <v-text-field v-model="model.salary_rate"
                              required :error-messages="errors"
                              label="Зарплата, сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="type" rules="required">
                <v-select v-model="model.type"
                          :items="StaffWorkTypes"
                          item-value="id" item-text="title"
                          required :error-messages="errors"
                          label="Тип cотрудника"
                >
                  <template #item="{item}">
                    <staff-work-type :type="item.id" left with-title/>
                  </template>
                  <template #selection="{item}">
                    <staff-work-type :type="item.id" left with-title/>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="phone" rules="required">
                <v-text-field-integer v-model="model.phone"
                                      label="Мобильный"
                                      :properties="{
                                        prefix: '+998',
                                        required: true,
                                        'error-messages': errors,
                                        maxlength: 9,
                                      }"
                                      :options="{
                                        inputMask: '(##) ###-##-##',
                                        outputMask: '#########',
                                        empty: null,
                                        applyAfter: false,
                                        alphanumeric: true,
                                        lowerCase: false,
                                      }"
                ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="passport" rules="required|max:9">
                <v-text-field-simplemask v-model="model.passport"
                                         label="Паспорт"
                                         :properties="{
                                            maxlength: 9,
                                            required: true,
                                            'error-messages': errors,
                                         }"
                                         :options="{
                                            inputMask: '## #######',
                                            outputMask: '#########',
                                            empty: '',
                                            applyAfter: false,
                                            alphanumeric: true,
                                          }"
                ></v-text-field-simplemask>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="pinfl" rules="integer">
                <v-text-field-integer v-model="model.pinfl"
                                      :properties="{
                                        required: true,
                                        maxlength: 14,
                                        'error-message': errors,
                                      }"
                                      :options="{
                                        inputMask: '# ###### ### ### #',
                                        outputMask: '##############',
                                        empty: null,
                                        applyAfter: false,
                                      }"
                                      label="ПИНФЛ"
                ></v-text-field-integer>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <v-checkbox v-model="model.is_active"
                          color="success" label="Активный"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="model.description"
                            required rows="3"
                            label="Описание"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'staff-form',

    computed: {
      ...mapState('Staff', ['saving', 'model', 'StaffWorkTypes']),
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('staff:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Staff/save')
          .then(() => {
            this.$emit('staff:form:saved')
          })
      },
    },
  }
</script>
