var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить карточку сотрудника' : 'Создать карточку сотрудника'))]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Фамилия"},model:{value:(_vm.model.last_name),callback:function ($$v) {_vm.$set(_vm.model, "last_name", $$v)},expression:"model.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Имя"},model:{value:(_vm.model.first_name),callback:function ($$v) {_vm.$set(_vm.model, "first_name", $$v)},expression:"model.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"middle_name","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Отчество"},model:{value:(_vm.model.middle_name),callback:function ($$v) {_vm.$set(_vm.model, "middle_name", $$v)},expression:"model.middle_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"label":"Мобильный","properties":{
                                      prefix: '+998',
                                      required: true,
                                      'error-messages': errors,
                                      maxlength: 9,
                                    },"options":{
                                      inputMask: '(##) ###-##-##',
                                      outputMask: '#########',
                                      empty: null,
                                      applyAfter: false,
                                      alphanumeric: true,
                                      lowerCase: false,
                                    }},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"passport","rules":"required|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-text-field-simplemask',{attrs:{"label":"Паспорт","properties":{
                                          maxlength: 9,
                                          required: true,
                                          'error-messages': errors,
                                       },"options":{
                                          inputMask: '## #######',
                                          outputMask: '#########',
                                          empty: '',
                                          applyAfter: false,
                                          alphanumeric: true,
                                        }},model:{value:(_vm.model.passport),callback:function ($$v) {_vm.$set(_vm.model, "passport", $$v)},expression:"model.passport"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"pinfl","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('v-text-field-integer',{attrs:{"properties":{
                                      required: true,
                                      maxlength: 14,
                                      'error-message': errors,
                                    },"options":{
                                      inputMask: '# ###### ### ### #',
                                      outputMask: '##############',
                                      empty: null,
                                      applyAfter: false,
                                    },"label":"ПИНФЛ"},model:{value:(_vm.model.pinfl),callback:function ($$v) {_vm.$set(_vm.model, "pinfl", $$v)},expression:"model.pinfl"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"required":"","rows":"3","label":"Описание"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать'))],1),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }