<template>
  <div>
    <v-row ref="filtersContainer">
      <v-col cols="4">
        <h2 class="mt-5 mb-2 font-weight-regular text--primary">История операций</h2>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="date1menu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_after" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="date2menu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_before" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="filter.amount_min" :disabled="!!fetchingHistory" type="number"
                      label="Мин сумма" prepend-inner-icon="mdi-format-horizontal-align-left"
                      clearable hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="filter.amount_max" :disabled="!!fetchingHistory" type="number"
                      label="Макс сумма" append-icon="mdi-format-horizontal-align-right"
                      clearable hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :loading="!!fetchingHistory" :items-per-page="filter.limit"
                  :items="histories"
                  :height="dataTableHeight" fixed-header hide-default-footer
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td class="text-center">{{(getPaymentType(item.capital_operatation)|| {}).name}}</td>
          <td class="text-center">{{item.date|date}}</td>
          <td class="text-right">{{item.amount|money}}</td>
          <td>{{item.comments}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>
  </div>
</template>

<script>
import moment from 'moment'
import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
import {mapGetters, mapState} from 'vuex'

import {hasFilter} from '@/mixins'

export default {
  name: 'owner-history',

  props: {
    ownerId: {
      required: true,
    }
  },

  mixins: [hasFilter],

  customFilters: {
    date_before: moment().format(SERVER_DATE_FORMAT),
    date_after: moment().subtract(1, 'month').format(SERVER_DATE_FORMAT),
    amount_min: '',
    amount_max: '',
  },

  data() {
    return {
      date1menu: false,
      date2menu: false,

      headers: [
        {text: '#', align: 'center', width: '70px'},
        {text: 'Операция', align: 'center', width: '200px'},
        {text: 'Дата', align: 'center', width: '120px'},
        {text: 'Сумма', align: 'right', width: '200px'},
        {text: 'Описание'},
      ]
    }
  },

  computed: {
    ...mapState('Owner', ['history', 'fetchingHistory']),
    ...mapGetters('Payment', ['getPaymentType']),

    data() {
      return this.history
    },

    histories() {
      return this.history?.results || []
    },

    meta() {
      return this.history?.type_total || {}
    },

    fromDateFormatted() {
      return moment(this.filter.date_after).format(USER_DATE_FORMAT)
    },

    toDateFormatted() {
      return moment(this.filter.date_before).format(USER_DATE_FORMAT)
    }
  },

  methods: {
    fetch() {
      return this.$store.dispatch('Owner/fetchHistory', {
        ...this.compiledFilters,
        owner: this.ownerId,
      })
    },

    dateChanged() {
      this.date1menu = false
      this.date2menu = false
      this.$nextTick(this.fetch)
    },
  },

  created() {
    this.fetch()
  }
}
</script>
