<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="6" class="d-flex align-center pb-0">
        <span class="text-h5">
          <v-icon color="black" class="pb-1">mdi-account-hard-hat-outline</v-icon> Сотрудники
        </span>
        <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
               @click="showForm()"
        >
          <v-icon left>mdi-plus</v-icon> Добавить
        </v-btn>
        <v-dialog v-model="formDialog" width="80vw" persistent>
          <staff-form v-if="formDialog"
                            @staff:form:saved="closeForm"
                            @staff:form:cancel="closeForm"
          ></staff-form>
        </v-dialog>
      </v-col>
      <v-col offset="3" cols="3" class="pb-0">
        <v-text-field v-model="filter.name" :disabled="!!fetching"
                      label="Поиск" prepend-inner-icon="mdi-magnify"
                      ref="name_filter" clearable hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :items="staffs" :headers="nonSortableHeaders"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>
            <staff-work-type :type="item.type" left/> {{item|full_name}}
            <v-icon right x-small :color="item.is_active ? 'success' : 'error'">mdi-circle</v-icon>
          </td>
          <td>{{item.phone|phone}}</td>
          <td>{{item.description|shorten}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" fullscreen v-model="detailDialog">
      <staff-details @dialog:close="closeDetails"
                     @dialog:edit="editForm"
      ></staff-details>
    </v-dialog>
  </v-container>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState, mapGetters, mapActions, mapMutations} = createNamespacedHelpers('Staff')

  import StaffDetails from '@/modules/staff/details'
  import StaffForm from '@/modules/staff/form'
  import {hasDialog, hasFilter} from '@/mixins'

  export default {
    name: 'staff-list',

    mixins: [hasFilter, hasDialog],

    refocusable: {
      name: 'name_filter',
    },

    customFilters: {
      name: '',
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
        'deleting',
      ]),

      headers() {
        return [
          {text: '#', align: 'center', width: '70'},
          {text: 'ФИО', value: 'name'},
          {text: 'Телефон', width: 200},
          {text: 'Описание'},
          {text: 'Действия', align: 'right', sortable: false},
        ]
      },

      staffs() {
        return this.data?.results || []
      },

      ...mapGetters(['getStaffWorkType'])
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      showForm(item) {
        if (item && item.id) {
          this.show(item.id)
        } else {
          this.setModel({})
        }
        this.formDialog = true
      },

      editForm(item) {
        this.showForm(item)
        this.detailDialog = false
      },

      showDetailsByStaffId(staffId) {
        this.show(staffId)
        this.detailDialog = true
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список сотрудников')

      if (this.$route.query.staff_id) {
        this.showDetailsByStaffId(this.$route.query.staff_id)
      }
    },

    components: {
      StaffForm,
      StaffDetails,
    }
  }
</script>
