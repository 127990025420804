<template>
  <div>
    <v-row ref="filtersContainer" class="history-totals mt-0 mb-0">
      <v-col cols="2" class="pb-0 indigo--text">
        <p class="font-weight-bold mb-0">
          <v-icon color="indigo">mdi-cash-fast</v-icon>  Остаток на начало
        </p>
        {{salaryRemains.remain_start|money}}
      </v-col>
      <v-col cols="2" class="pb-0 info--text darken-2">
        <div class="font-weight-bold mb-0">
          <v-icon color="info">mdi-cash-plus</v-icon> Отработано
        </div>
        {{salaryRemains.debit_credit.debet|money}}
      </v-col>
      <v-col cols="2" class="pb-0 success--text">
        <div class="font-weight-bold mb-0">
          <v-icon color="success">mdi-cash-check</v-icon> Оплачено
        </div>
        {{salaryRemains.debit_credit.credit|money}}
      </v-col>
      <v-col cols="2" class="pb-0 error--text">
        <div class="font-weight-bold mb-0">
          <v-icon color="error">mdi-cash-clock</v-icon> Остаток на конец
        </div>
        {{salaryRemains.remain_end|money}}
      </v-col>
      <v-col cols="2" class="pb-0">
        <div class="font-weight-bold mb-0">Отработанные дни</div>
        {{salaryRemains.worked_days}}
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="12"><v-divider/></v-col>
      <v-col cols="2" class="pt-2">
        <v-select v-model="year"
                  :items="years"
                  item-value="id" item-text="value"
                  label="за год" prepend-icon="mdi-calendar" hide-details
        ></v-select>
      </v-col>
      <v-col cols="10" v-if="availableMonths.length">
        <v-tabs v-if="availableMonths.length"
                v-model="monthIndex" optional
                align-with-title active-class="indigo white--text"
        >
          <v-tabs-slider color="primary"/>
          <v-tab v-for="monthData in availableMonths" :key="monthData.month">
            {{ monthData.month }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-data-table :loading="!!fetchingHistory" :items-per-page="filter.limit"
                  :items="histories"
                  :height="dataTableHeight"
                  :headers="nonSortableHeaders" hide-default-footer fixed-header
                  no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td class="text-center">{{item.date|date}}</td>
          <td class="text-center" :class="getPaymentType(item.type).color2 + '--text'">
            {{getPaymentType(item.type).staffListName}}
          </td>
          <td class="text-right">{{item.amount|money}}</td>
          <td>{{item.comments}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages">
      <template #right>
        <v-btn class="mt-2" text color="success">
          <v-icon>mdi-printer</v-icon>
          Печать
        </v-btn>
      </template>
    </pagination>
  </div>
</template>

<script>
import moment from 'moment'
import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
import {mapState, mapGetters} from 'vuex'

import {hasFilter} from '@/mixins'

export default {
  name: 'staff-history',

  props: {
    staffId: {
      required: true,
    }
  },

  mixins: [hasFilter],

  customFilters: {
    date_before: moment().endOf('month').format(SERVER_DATE_FORMAT),
    date_after: moment().startOf('month').format(SERVER_DATE_FORMAT),
  },

  data() {
    return {
      year: (new Date).getFullYear(),
      years: [2023, 2024, 2025, 2026].filter(d => 1 || d <= (new Date).getFullYear()).map(v => {
        return {id: v, value: v}
      }),
      monthIndex: -1,

      headers: [
        {text: '#', align: 'center', width: '70px'},
        {text: 'Дата', align: 'center', width: '200px'},
        {text: 'Тип', align: 'center', width: '70px'},
        {text: 'Сумма', align: 'right', width: '200px'},
        {text: 'Описание'},
      ]
    }
  },

  computed: {
    ...mapState('Staff', ['history', 'fetchingHistory']),
    ...mapGetters('Payment', ['getPaymentType']),
    ...mapState('SalaryStatement', ['salaryAvailableMonths']),

    salaryRemains() {
      if (this.availableMonths.length && this.availableMonths[this.monthIndex]) {
        return this.availableMonths[this.monthIndex]
      }

      return this.history?.salary_remain_final || {
        debit_credit: {debet: 0, credit: 0},
        remain_end: 0,
        remain_start: 0,
        worked_days: 3
      }
    },

    availableMonths() {
      let months = (this.salaryAvailableMonths || []).slice()
      months.sort((a, b) => a.month < b.month ? -1 : 1)
      return months
    },

    data() {
      return this.history
    },

    totals() {
      return this.history?.total || {}
    },

    histories() {
      return this.history?.results || []
    },

    meta() {
      return this.history?.type_total || {}
    },

    dataTableExtraOffset() {
      return 64
    },
  },

  methods: {
    fetchFilters(year) {
      return this.$store.dispatch('SalaryStatement/getAvailableDates', {
        id: this.staffId, year: year || this.year
      })
    },

    setDefaultMonth() {
      this.monthIndex = this.availableMonths.length - 1
      if (this.monthIndex < 0) {
        this.$store.commit('Staff/setHistory', null)
      }
    },

    setDefaultFilters() {
      let month = this.availableMonths[this.monthIndex]?.month

      if (month) {
        this.filter.date_after = moment(month).startOf('month').format(SERVER_DATE_FORMAT)
        this.filter.date_before = moment(month).endOf('month').format(SERVER_DATE_FORMAT)
      }
    },

    fetch() {
      this.setDefaultFilters()

      return this.$store
        .dispatch('Staff/fetchHistory', {
          ...this.compiledFilters,
          staff: this.staffId,
        })
    },
  },

  watch: {
    year(newVal) {
        if (newVal) {
          this.fetchFilters(newVal)
            .then(() =>
              this.$nextTick(this.setDefaultMonth)
            )
        }
    },

    monthIndex(newVal, oldVal) {
        if (newVal > -1) {
          this.fetch('monthIndex', newVal, oldVal)
        }
    }
  },

  created() {
    this.fetchFilters()
        .then(() =>
          this.$nextTick(this.setDefaultMonth)
        )
  },
}
</script>

<style>
.history-totals {
  font-size: 1.2em;
}
</style>
