<template>
  <v-card :loading="!!fetchingDetails">
    <template #progress>
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </template>

    <v-card-title>
      Карточка сотрудника {{model|full_name}} <span v-if="!model.is_active">(неактивный)</span>
      <v-btn color="primary" small dark rounded @click="$emit('dialog:edit', model)" class="ml-3">
        <v-icon small left>mdi-pen</v-icon> Изменить
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-6 text-subtitle-1">
        {{model.passport}}
      • {{model.pinfl}}
      • {{model.phone|phone}}
      • {{model.description}}
      • <staff-work-type :type="model.type" with-title/>
      • {{model.salary_rate|money}}
    </div>

    <v-card-text>
      <staff-history v-if="!fetchingDetails" :staff-id="model.id"></staff-history>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState} = createNamespacedHelpers('Staff')

  import StaffHistory from '@/modules/staff/history'

  export default {
    name: 'staff-details',

    computed: {
      ...mapState(['fetchingDetails', 'model']),
    },

    components: {
      StaffHistory,
    }
  }
</script>
