<template>
  <v-card :loading="!!fetching">
    <template #progress>
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </template>

    <v-card-title>
      Карточка учредителя: {{model|full_name}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <owner-history v-if="!fetching" :owner-id="model.id"></owner-history>
    </v-card-text>

    <v-card-actions v-if="false" class="pb-4">
      <v-btn color="primary" dark class="mt-5" rounded @click="$emit('dialog:edit', model)">
        <v-icon small left>mdi-pen</v-icon> Изменить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'
  const {mapState} = createNamespacedHelpers('Owner')

  import OwnerHistory from '@/modules/owner/history'

  export default {
    name: 'owner-details',

    computed: {
      ...mapState(['fetching', 'model']),
    },

    components: {
      OwnerHistory,
    }
  }
</script>
